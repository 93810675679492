/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// import PropTypes from "prop-types";

import React from "react";
import { graphql } from "gatsby";
import tw, { css } from "twin.macro";
import Grid from "~styles/Grid.jsx";
import * as A from "~styles/Animations.jsx";
import * as T from "~styles/Typography.jsx";
import { Go, LineSwipe, WaveCanvas } from "~components";
import { getColor } from "~utils/helpers";

import { ReactComponent as ArrowRight } from "~assets/svg/arrow-right.svg";

/** ============================================================================
 * @component
 * Wavy line section usually appearing ATF.
 */
const IntroductionSection = ({
  data: { backgroundColor, fontColor, heading, headingAlignment, body, buttons }
}) => {
  let iconColor;
  let buttonColor = `black`;

  switch (backgroundColor?.title?.toLowerCase()) {
    case `white`:
    case `grey`:
    case `gray`:
    case `faint blue`:
      iconColor = `#84bbd9`;
      break;

    case `bright blue`:
    case `sky blue`:
    case `deep blue`:
    case `light blue`:
      iconColor = getColor(`white`);
      break;

    default:
      iconColor = `#84bbd9`;
      break;
  }

  switch (fontColor?.title?.toLowerCase()) {
    case `white`:
      buttonColor = `white`;
      break;

    default:
      buttonColor = `black`;
      break;
  }

  // ---------------------------------------------------------------------------
  // render

  return (
    <div
      id={heading?.replace(/\s+/g, `-`).toLowerCase()}
      css={[
        css`
          background: ${backgroundColor?.hex};
          color: ${fontColor?.hex};

          @media screen and (min-width: 1025px) {
            height: 660px;
          }

          @media screen and (min-width: 1280px) {
            height: 720px;
          }

          @media screen and (min-width: 1440px) {
            height: 800px;
          }
        `,
        tw`w-full relative flex items-start pt-48 md:pt-64 pb-16 md:pb-8 lg:pb-12 xl:pb-20`
      ]}
    >
      <WaveCanvas />

      <Grid _css={[tw`z-20`]}>
        <header
          css={[
            css`
              //
            `,
            headingAlignment && headingAlignment !== `above`
              ? tw`col-span-12 md:col-span-9 lg:col-span-8 mb-12 md:mb-20 relative flex flex-col`
              : tw`col-span-12 md:col-span-9 lg:col-span-8 mb-12 md:mb-32 relative flex flex-col`
          ]}
        >
          <div
            css={[
              css`
                order: ${headingAlignment && headingAlignment !== `above`
                  ? `2`
                  : `1`};
              `,
              headingAlignment && headingAlignment !== `above`
                ? tw`relative flex items-center md:mt-8`
                : tw`relative flex items-center mb-12 md:mb-6 lg:mb-12`
            ]}
          >
            <T.Head
              _css={[
                css`
                  ${A.Keyframes(`appearDown`, {
                    delay: `300ms`,
                    settings: `1s ${A.EASING_CUBIC} forwards`
                  })}
                `,
                tw`whitespace-pre-wrap`
              ]}
              font="4"
              level="1"
            >
              <span dangerouslySetInnerHTML={{ __html: heading }} />
            </T.Head>

            {(!headingAlignment ||
              headingAlignment === `` ||
              headingAlignment === `above`) && (
              <div css={[tw`w-32 relative block ml-5`]}>
                <LineSwipe color={iconColor} delay={500} duration={2000} />
              </div>
            )}
          </div>

          <T.Head
            _css={[
              css`
                ${A.Keyframes(`appearDown`, {
                  delay: `500ms`,
                  settings: `1s ${A.EASING_CUBIC} forwards`
                })}

                order: ${headingAlignment && headingAlignment !== `above`
                  ? `1`
                  : `2`};
              `,
              headingAlignment && headingAlignment !== `above`
                ? tw`whitespace-pre-wrap font-bold`
                : tw`whitespace-pre-wrap font-bold`
            ]}
            font="1"
            level="1"
          >
            {body}
          </T.Head>
        </header>

        {buttons?.[0] &&
          buttons.map(({ text, url }, linkIndex) => (
            <article
              key={`introduction-section-link-${text}`}
              css={[
                css`
                  color: ${buttonColor.hex};
                  ${A.Keyframes(`appearRight`, {
                    delay: `${175 * (linkIndex + 1) + 750}ms`,
                    settings: `1s ${A.EASING_CUBIC} forwards`
                  })};
                `,
                linkIndex === 0
                  ? tw`col-span-12 md:col-span-4 md:col-start-1 relative mb-1 md:mb-6 py-2 md:py-0 md:px-2`
                  : tw`col-span-12 md:col-span-4 relative mb-1 md:mb-6 py-2 md:py-0 md:px-2`
              ]}
            >
              <Go
                to={url}
                _css={[
                  css`
                    transition: background 0.3s ${A.EASING_CUBIC};

                    border-color: ${buttonColor || fontColor?.hex};

                    p,
                    svg {
                      transition: transform 0.3s ${A.EASING_CUBIC};
                    }

                    &:hover {
                      background: rgba(125, 125, 145, 0.08);

                      p {
                        transform: translate3d(0.75rem, 0, 0);
                      }
                      svg {
                        transform: translate3d(-0.75rem, 0, 0);
                      }
                    }
                  `,
                  tw`w-full h-full relative block pt-3 lg:pt-5 pb-3 lg:pb-5 border-t`
                ]}
              >
                <div
                  css={[
                    tw`w-full h-full relative flex items-center justify-between`
                  ]}
                >
                  <T.Body
                    _css={[
                      css`
                        width: calc(100% - 2rem);
                        color: ${buttonColor || fontColor?.hex};
                        @media screen and (min-width: 1025px) {
                          width: auto;
                        }
                      `
                    ]}
                    font="2"
                  >
                    {text}
                  </T.Body>

                  <ArrowRight
                    css={[tw`w-6`]}
                    fill={buttonColor || fontColor?.hex}
                  />
                </div>
              </Go>
            </article>
          ))}
      </Grid>
    </div>
  );
};

export default IntroductionSection;

/** ============================================================================
 * graphql
 */
export const query = graphql`
  fragment IntroductionSectionFragment on SanityIntroductionSection {
    _type

    backgroundColor {
      hex
      title
    }
    fontColor {
      hex
      title
    }

    body
    heading
    headingAlignment

    buttons {
      text
      url
    }
  }
`;
